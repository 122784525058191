import React from 'react'
import CertificateList from '../constructors/CertificateList';
import Certificate from './Certificate';
import { useTranslation } from 'react-i18next';
import "./styles/CertificatesList.scss";
export default function CertificatesList() {
    const {t} = useTranslation();

  return (
    <div className='certificates-container'>
        <h4 className="title">{t("Our_certificates")}</h4>
        <div className='certificates-list'>
            {
                CertificateList.map(item=>(
                    <Certificate title={item.title} url={item.url} key={item.id} />
                ))
            }
        </div>
    </div>
  )
}
