import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import About from './pages/About';
import Referance from './pages/Referance';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Services } from './pages/Services';
import './i18n';
import ScrollToTop from './components/ScrollToTop';
import PersonalData from './pages/PersonalData';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar/>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/kurumsal" element={<About />}/>
        <Route path="/referanslarimiz" element={<Referance />} />
        <Route path="/iletisim" element={<Contact />} />
        <Route path="/hizmetlerimiz" element={<Services />} />
        <Route path="/kisisel-verilerin-korunmasi" element={<PersonalData />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
