import React from 'react'
import './styles/AboutBlackList.scss';
import { useTranslation } from 'react-i18next';

export default function AboutBlackList({customClassName}) {
  const {t} = useTranslation();
  return (
    <section className={`container-black ${customClassName}`}>
    <div className='container-black_list'>
      <div>
        <picture>
          <source media='(max-width:415px)' srcSet={require('../assets/images/Union-414.png')}/>
          <source media='(max-width:768px)' srcSet={require('../assets/images/Union-768.png')} />
          <source media='(min-width:769px)' srcSet={require('../assets/images/Union.png')}/>
          <img src={require('../assets/images/Union-414.png')} alt='union' />
        </picture>
      
        <p>{t("10th_Year_in_the_Industry")}</p>
      </div>
      <div>
        <picture>
          <source media='(max-width:415px)' srcSet={require('../assets/images/Teleport-414.png')} />
          <source media='(max-width:768px)' srcSet={require('../assets/images/Teleport-768.png')} />
          <source media='(min-width:769px)' srcSet={require('../assets/images/Teleport.png')} />
          <img src={require('../assets/images/Teleport-414.png')} alt='teleport' />
        </picture>

        <p>{t("Professional_Services")}</p>
      </div>
      <div>
      <picture>
          <source media='(max-width:415px)' srcSet={require('../assets/images/Vector-414.png')} />
          <source media='(max-width:768px)' srcSet={require('../assets/images/Vector-768.png')} />
          <source media='(min-width:769px)' srcSet={require('../assets/images/Vector.png')} />
          <img src={require('../assets/images/Vector-414.png')} alt='vector' />
        </picture>

        <p>+ {t("80_Solution_Partners")}</p>
      </div>
    </div>
  </section>
  )
}
