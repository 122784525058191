const ServiceList = [
  {
    id:0,
    imgPosition:"left",
    ticket:"Broadcasting",
    imgDesktop:"compression-1440",
    img:"compression-img",
    service: "Compression_services",
    content: "Compression_services_content", 
  },
  {
    id:1,
    img:"share-img",
    imgPosition:"right",
    imgDesktop:"kablo-1440",
    ticket:"Broadcasting",
    service: "Content_intake_and_distribution",
    content: "Content_intake_and_distribution_content" 
  },
  {
    id:2,
    img:"rf-img",
    imgPosition:"left",
    imgDesktop:"rf-1440",
    ticket:"Broadcasting",
    service: "Rf_systems",
    content: "Rf_systems_content" 
  },
  {
    id:3,
    img:"loudness-img",
    imgDesktop:"loudness-1440",
    imgPosition:"right",
    ticket:"Monitoring_reporting_services",
    service: "Loudness_monitoring",
    content: "Loudness_monitoring_content" 
  },
  {
    id:4,
    img:"uptime-img",
    imgPosition:"left",
    imgDesktop:"uptime-1440",
    ticket:"Monitoring_reporting_services",
    service: "Uptime_raporting",
    content: "Uptime_raporting_content",
    img2:"general-transport",
    img2Tablet:"general-transport-768",
    img2Desktop:"general-transport-1440"
  },
  {
    id:5,
    img:"screen-room",
    imgPosition:"right",
    ticket:"Broadcasting",
    service: "Playout_services",
    imgDesktop:"playout-1440",
    content: "Playout_services_content",
    contentItem: [{title:'All_ip_based'},{title:'Complex_chart_tools'},{title:'Control_monitoring'},{title:'Live_streaming_services'},{title:'State_of_the_art_automation'},{title:'Full_redundancy'}]
  },
  {
    id:6,
    img:"work-with-comp",
    imgDesktop:"ott-1440",
    imgPosition:"left",
    ticket:"Broadcasting",
    service: "OTT",
    content: "OTT_content",
  },
  {
    id:7,
    img:"anten",
    imgPosition:"right",
    imgDesktop:"anten-2-1440",
    ticket:"Infrastructure",
    service: "Disaster_recovery_services",
    content: "Disaster_recovery_services_content",
    img2:"istanbul-transport",
    img2Tablet:"felaket-768",
  },
];

export default ServiceList;