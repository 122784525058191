// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-btn {
  color: #FCAF17;
  border-radius: 40px;
  padding: 3px 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  display: inline-block;
}
@media screen and (max-width: 415px) {
  .info-btn {
    font-size: 12px;
    line-height: 15.6px;
    height: 24px;
  }
}

.services-info-black {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 40px;
  padding: 6px 14px;
  width: -moz-fit-content;
  width: fit-content;
  gap: 10px;
  margin-top: 0px;
}
@media screen and (max-with: 768px) {
  .services-info-black {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 415px) {
  .services-info-black {
    flex-direction: column;
    align-items: center;
    width: 168px;
    justify-content: center;
    text-align: center;
    height: 126px;
  }
}
@media screen and (max-width: 390px) {
  .services-info-black {
    width: 48%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/styles/InfoButton.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,mBAAA;EACA,iBAAA;EACA,oCAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;AACJ;AAAI;EAVJ;IAWQ,eAAA;IACA,mBAAA;IACA,YAAA;EAGN;AACF;;AAAA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,SAAA;EACA,eAAA;AAGJ;AAFI;EAfJ;IAgBQ,eAAA;IACA,iBAAA;EAKN;AACF;AAJI;EAnBJ;IAoBQ,sBAAA;IACA,mBAAA;IACA,YAAA;IACA,uBAAA;IACA,kBAAA;IACA,aAAA;EAON;AACF;AANI;EA3BJ;IA4BQ,UAAA;EASN;AACF","sourcesContent":[".info-btn {\n    color: #FCAF17;\n    border-radius: 40px;\n    padding: 3px 16px;\n    border: 1px solid rgba(0, 0, 0, 0.2);\n    text-align: center;\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 26px;\n    display: inline-block;\n    @media screen and (max-width:415px) {\n        font-size: 12px;\n        line-height: 15.6px;\n        height: 24px;\n    }\n    \n}\n.services-info-black {\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 27px;\n    letter-spacing: 0em;\n    text-align: left;\n    background-color: #000;\n    color: #fff;\n    display: flex;\n    align-items: center;\n    border-radius: 40px;\n    padding: 6px 14px;\n    width: fit-content;\n    gap: 10px;\n    margin-top: 0px;\n    @media screen and (max-with:768px) {\n        font-size: 16px;\n        line-height: 24px;\n    }\n    @media screen and (max-width:415px) {\n        flex-direction: column;\n        align-items: center;\n        width: 168px;\n        justify-content: center;\n        text-align: center;\n        height: 126px; \n    }\n    @media screen and (max-width:390px) {\n        width: 48%;\n    }\n}   \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
