import React from "react";
import "./styles/MoreButton.scss";
import { Link } from "react-router-dom";

export default function MoreButton({
  title,
  url,
  bgColor,
  textColor,
  icon,
  customClass,
  isDownload = false,
}) {
  if (isDownload) {
    return (
      <a
        className={`${customClass} more-btn`}
        href={url}
        style={{ backgroundColor: bgColor, color: textColor }}
        download={true}
      >
        {title}
        <img src={icon} alt="icon" className="more-btn_icon" />
      </a>
    );
  }
  return (
    <Link
      className={`${customClass} more-btn`}
      to={url}
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      {title}
      <img src={icon} alt="icon" className="more-btn_icon" />
    </Link>
  );
}
