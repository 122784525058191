import React, { useState } from "react";
import "./styles/HomeServicesComponent.scss";
import InfoButton from "./InfoButton";
import MoreButton from "./MoreButton";
import ServiceList from "../constructors/ServicesList";
import ServiceImgList from "../constructors/ServicesImgList";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";

export default function HomeServicesComponent() {
  const { t } = useTranslation();
  const [radioValue, setRadioValue] = useState("turkey-beam");
  const handleClick = (value) => {
    setRadioValue(value);
  };
  return (
    <section className="home-services_container">
      <h4 className="home-services_title">{t("Our_Services")}</h4>
      <div className="home-services_cont">
        <div className="home-services_top">
          {ServiceImgList.map((serviceImg) => (
            <div className="home-services_part" key={serviceImg.id}>
              <img src={serviceImg.imgUrl} alt={serviceImg.title} />
              <span className="services-part_title">{serviceImg.title}</span>
              <span className="services-part_desc">{serviceImg.desc}</span>
              {
                serviceImg.desc2 ? <span className="services-part_desc">{serviceImg.desc2}</span> : null
              }
            </div>
          ))}
        </div>
        <div className="home-services_info-cont hidden-sm">
          {ServiceList.map((service) => (
            <InfoButton
              key={service.id}
              title={t(service.title)}
              customClass="services-info-black"
              showIcon="true"
              iconUrl={require("../assets/images/Select.png")}
            />
          ))}
        </div>

        {/* mobile carousel */}
        <div className="show-sm">
          <Carousel controls={false}>
            {(() => {
              let arr = [];
              for (
                let index = 0;
                index <= ServiceList.length / 2 + 1;
                index += 4
              ) {
                const content = [];
                {
                  /** ilk 4 madddeyi alır */
                }
                content.push(ServiceList[index]);
                content.push(
                  ServiceList[index + 1] ? ServiceList[index + 1] : null
                );
                content.push(
                  ServiceList[index + 2] ? ServiceList[index + 2] : null
                );
                content.push(
                  ServiceList[index + 3] ? ServiceList[index + 3] : null
                );

                let arry2 = [];
                content.map((el) =>
                  el !== null
                    ? arry2.push(
                        <InfoButton
                          key={el?.id}
                          title={t(el?.title)} 
                          customClass="services-info-black"
                          showIcon="true"
                          iconUrl={require("../assets/images/Select.png")}
                        />
                      )
                    : null
                );
                arr.push(<Carousel.Item key={index}>{[...arry2]}</Carousel.Item>);
              }
              return arr;
            })()}
          </Carousel>
        </div>
        {/** mobile carousel  end*/}
      </div>

      <div className="yayin-container">
        <div className="yayin-text">
          <InfoButton title={t("Infrastructure")} customClass="info-btn" />
          <h4 className="yayin-title">
            {t("Satellite_Broadcast_Transmission_Services_Coverage_Area")}
          </h4>
          <p className="yayin-text_desc">{t("yayin-text_desc")}</p>

          <button
            className={
              radioValue === "turkey-beam"
                ? "active-yayin yayin-select-container"
                : "yayin-select-container"
            }
            id="turkey-beam"
            onClick={() => handleClick("turkey-beam")}
          >
            <div className="yayin-select_top">
              <img
                src={require("../assets/images/Satellite.png")}
                alt="satellite"
                className="satellite"
              />
              <div className="yayin-select_text-box">
                <div className="yayin-select-text_cont">
                  <span className="yayin-select_title">
                    TURKSAT 4A TURKEY BEAM
                  </span>
                  <div className="yayin-select_desc">
                    <span>COVERAGE AREA</span>
                    <span>TÜRKİYE</span>
                  </div>
                </div>
                <span className="yayin-select_mhz">2x 33 MHz</span>
              </div>

              {radioValue === "turkey-beam" ? (
                <img
                  src={require("../assets/images/Select-black.png")}
                  className="select-icon"
                  alt="select"
                />
              ) : (
                <img
                  src={require("../assets/images/Select-empty.png")}
                  className="select-icon"
                  alt="select"
                />
              )}
            </div>
          </button>

          <button
            className={
              radioValue === "west-beam"
                ? "active-yayin yayin-select-container"
                : "yayin-select-container"
            }
            id="west-beam"
            onClick={() => handleClick("west-beam")}
          >
            <div className="yayin-select_top">
              <img
                src={require("../assets/images/Satellite.png")}
                alt="satellite"
                className="satellite"
              />
              <div className="yayin-select_text-box">
                <div className="yayin-select-text_cont">
                  <span className="yayin-select_title">
                    TURKSAT 4A TURKEY WEST BEAM
                  </span>
                  <div className="yayin-select_desc">
                    <span>COVERAGE AREA</span>
                    <span>WEST</span>
                  </div>
                </div>
                <span className="yayin-select_mhz">2x 33 MHz</span>
              </div>
              {radioValue === "west-beam" ? (
                <img
                  src={require("../assets/images/Select-black.png")}
                  className="select-icon"
                  alt="select"
                />
              ) : (
                <img
                  src={require("../assets/images/Select-empty.png")}
                  className="select-icon"
                  alt="select"
                />
              )}
            </div>
          </button>
        </div>
        <div className="yayin-img">
          {" "}
          {radioValue === "turkey-beam" ? (
            <picture>
              <source
                media="(max-width:415px)"
                srcSet={require("../assets/images/türkiye-kapsama-alanı-414.png")}
              />
              <source
                media="(max-width:768px)"
                srcSet={require("../assets/images/türkiye-kapsama-alanı-768.png")}
              />
              <source
                media="(min-width:769px)"
                srcSet={require("../assets/images/türkiye-kapsama-alanı.png")}
              />
              <img
                src={require("../assets/images/türkiye-kapsama-alanı-414.png")}
                alt="alan"
              />
            </picture>
          ) : (
            <picture>
              <source
                media="(max-width:415px)"
                srcSet={require("../assets/images/mobil-westbeam.png")}
              />
              <source
                media="(max-width:768px)"
                srcSet={require("../assets/images/tablet-westbeam.png")}
              />
              <source
                media="(min-width:769px)"
                srcSet={require("../assets/images/west-beam.png")}
              />
              <img
                src={require("../assets/images/mobil-westbeam.png")}
                alt="west"
              />
            </picture>
          )}
        </div>
      </div>

      <div className="compression-container">
        <div className="compression-img" />

        <div className="compression_text-box">
          <InfoButton title={t("Broadcasting")} customClass="info-btn" />
          <h4 className="compression_title">{t("Compression_Services")}</h4>
          <p className="compression_desc">{t("compression_desc")}</p>
        </div>
      </div>
      <picture>
              <source
                media="(max-width:415px)"
                srcSet={require("../assets/images/compression-414.png")}
              />
              <source
                media="(max-width:768px)"
                srcSet={require("../assets/images/compression.png")}
              />

              <img
                src={require("../assets/images/mobil-westbeam.png")}
                alt="west"
                className="compression-md-show"
              />
            </picture>

      <div className="services-btn-cont">
        <MoreButton
          title={t("All_Our_Services")}
          customClass="showHover"
          icon={require("../assets/images/Forward.png")}
          color="#000"
          bgColor="#FCAF17"
          url="/hizmetlerimiz"
        />
      </div>
    </section>
  );
}
