// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Articulat-CF-Medium.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@font-face {
    font-family: "Articulat CF";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}`, "",{"version":3,"sources":["webpack://./src/assets/font/font-articulatcf.scss"],"names":[],"mappings":";AACA;IACI,2BAA2B;IAC3B,+DAAwD;AAC5D","sourcesContent":["\n@font-face {\n    font-family: \"Articulat CF\";\n    src: url(\"./Articulat-CF-Medium.otf\") format(\"opentype\");\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
