import React from 'react'
import './styles/HomeReference.scss';
import MoreButton from '../components/MoreButton';
import InfoButton from '../components/InfoButton';
import { useTranslation } from 'react-i18next';

export default function HomeReference() {
  const {t} = useTranslation();
  return (
    <>
        <section className="ref-container">
            <div className='ref-container_text'>
            <InfoButton title={t("References")} customClass="info-btn"/>
            <h4 className='ref-container_title'>{t("We_Touch_the_Lives_of_Millions_of_People_with_Our_Values_and_Goals")}</h4>
            </div>
    
            <picture>
            <source media="(max-width:415px)" srcSet={require('../assets/images/reference-414.png')}/>
            <source media="(max-width:768px)" srcSet={require('../assets/images/reference-768.png')}/>
            <source media="(min-width:769px)" srcSet={require('../assets/images/reference.png')}/>
            <img src={require("../assets/images/reference-414.png")} alt="referans" />
            </picture>
        </section>  
        <div className='ref-container_btn'>
            <MoreButton title={t("See_All")} customClass="showHover" icon={require('../assets/images/Forward.png')} color='#000' bgColor='#FCAF17' url='/referanslarimiz' />
        </div>
    </>
  )
}
