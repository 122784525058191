// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./assets/font/font-articulatcf.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  font-family:  "Articulat CF";
}
.text-gray {
  color: #808080;
}
.text-white {
  color: #fff;
}
.mr-35 {
  margin-right: 35px;
}
.ml-20 {
  margin-left: 20px;
}

.px-30 {
  padding:0 30px 0 30px;
}
.bg-dark {
  background-color: #000;
}
.bg-white {
  background-color: #fff;
}
.fw-500 {
  font-weight: 500;
} 
.fw-400 {
  font-weight: 400;
}
.show-md, .show-sm {
  display: none;
}
@media screen and (max-width: 768px) {
  .show-md {
    display: block !important;
  }
  .hidden-md {
    display: none !important;
  }
}
@media screen and (max-width: 415px) {
  .show-sm {
    display: block !important;
  }
  .hidden-sm {
    display: none !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;EACE,sBAAsB;EACtB,4BAA4B;AAC9B;AACA;EACE,cAAc;AAChB;AACA;EACE,WAAW;AACb;AACA;EACE,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;AACA;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,wBAAwB;EAC1B;AACF;AACA;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,wBAAwB;EAC1B;AACF","sourcesContent":["@import url(\"../src/assets/font/font-articulatcf.scss\");\n* {\n  box-sizing: border-box;\n  font-family:  \"Articulat CF\";\n}\n.text-gray {\n  color: #808080;\n}\n.text-white {\n  color: #fff;\n}\n.mr-35 {\n  margin-right: 35px;\n}\n.ml-20 {\n  margin-left: 20px;\n}\n\n.px-30 {\n  padding:0 30px 0 30px;\n}\n.bg-dark {\n  background-color: #000;\n}\n.bg-white {\n  background-color: #fff;\n}\n.fw-500 {\n  font-weight: 500;\n} \n.fw-400 {\n  font-weight: 400;\n}\n.show-md, .show-sm {\n  display: none;\n}\n@media screen and (max-width: 768px) {\n  .show-md {\n    display: block !important;\n  }\n  .hidden-md {\n    display: none !important;\n  }\n}\n@media screen and (max-width: 415px) {\n  .show-sm {\n    display: block !important;\n  }\n  .hidden-sm {\n    display: none !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
