const ServiceImgList = [
    {
        id: 0,
        imgUrl: require("../assets/images/HD.png"),
        title: 'HD',
        desc: 'FTA: 7',
        desc2: 'SCRAMBLED: 33'
    },
    {
        id: 1,
        imgUrl: require("../assets/images/SD.png"),
        title: 'SD',
        desc: 'FTA: 25'
    },
    {
        id: 2,
        imgUrl: require("../assets/images/Radio.png"),
        title: 'Radio',
        desc: 'FTA: 12',
        desc2: 'SCRAMBLED: 50'
    }
];
export default  ServiceImgList;