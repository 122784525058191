import React from 'react'
import MoreButton from './MoreButton'
import { useTranslation } from 'react-i18next';

export default function Certificate({title,url}) {
    const {t} = useTranslation();
  return (
    <div className='certificates-item'>
        <span className='pdf'>PDF</span>
        <h4>{title}</h4>
        <span className='borders'></span>
        <MoreButton title={t("pdf_download")} bgColor="#FCAF17" textColor="#000" url={require(`../../public/files/${url}`)} customClass="pdf-download"  icon={require('../assets/images/Forward.png')} isDownload={true} />
    </div>
  )
}
