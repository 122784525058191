import React from 'react';
import { Row, Col} from 'react-bootstrap';
import ContactForm from '../components/ContactForm';
import './styles/Contact.scss';
import { useTranslation } from 'react-i18next';

export default function Contact() {
  const {t} = useTranslation();
  const [successinfo, setSuccessinfo ] = React.useState(false);

  return (
    <>
      { successinfo &&
        <div className="form-success">
          <img src={require('../assets/images/success.svg').default} alt="Success"/>
          <span>{t("form_sent")}</span>
        </div>
      }
      <div className="contact">
        <Row className='content'>
          <Col md="6" className='form'>
            <Row className='contact-header'>
              <Col>
                <h1 className='contact-header__title'>{t("Contact_us")}</h1>
                <div className='contact-header__subtitle'>{t("contact_page_desc")}</div>
              </Col>
            </Row>
            <ContactForm setSuccessinfo = { setSuccessinfo }/>
          </Col>
          <Col md="6" className='mapCol'>
            <a target="_blank" href="https://www.google.com/maps/place/Demir%C3%B6ren+Medya+Center/@41.0626759,28.834175,14z/data=!4m6!3m5!1s0x14caa5743bcf8bdd:0xb997e29073854302!8m2!3d41.061229!4d28.847674!16s%2Fg%2F11g1lxfnkg?entry=ttu">
              <picture >
                <source media='(min-width:1440px)' srcSet={require('../assets/images/address-1440.png')} />
                <source media='(min-width:768px)' srcSet={require('../assets/images/address-768.png')} />
                <img className='mw-100 img' src={require('../assets/images/address-img.png')} alt='address' />
              </picture>
            </a>
          </Col>
        </Row>
        <Row className='info'>
          <Col md="4">
            <div className='info-item'>
              <div>
                <img className='info-item__icon' src={require('../assets/images/phonesvg.svg').default} alt="Phone"/>
              </div>
              <div className='info-item__text'>+90 212 413 5111</div>
            </div>
          </Col>
          <Col md="4">
            <div className='info-item'>
              <div>
                <img className='info-item__icon' src={require('../assets/images/mailsvg.svg').default} alt="Mail" />
              </div>
              <div className='info-item__text'>info@demirorenhaberlesme.com.tr</div>
            </div>
          </Col>
          <Col md="4">
            <div className='info-item'>
              <div>
                <img className='info-item__icon' src={require('../assets/images/buildingsvg.svg').default} alt="Building"/>
              </div>
              <div className='info-item__text'>Demirören Medya Center 100. Yıl Mah. 2264 Sok. No: 1/21 34218 Bağcılar / İstanbul</div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}