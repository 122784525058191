import React from 'react';
import './styles/About.scss';
import AboutBlacklist from '../components/AboutBlackList';
import { useTranslation } from 'react-i18next';
import BGYSList from '../components/BGYSList';
import CertificatesList from '../components/CertificatesList';
export default function About() {
  const {t} = useTranslation();
  return (
    <div className='about'>
        <p className='about_title'>{t("about_title")}</p>
        <AboutBlacklist customClassName='about-page'/>
        <picture>
          <source media="(max-width:415px)" srcSet={require("../assets/images/demirorenmedya-414.png")} />
          <source media="(max-width:768px)" srcSet={require("../assets/images/demirorenmedya-768.png")} />
          <source media="(min-width:769px)" srcSet={require('../assets/images/demirorenmedya.png')} />
          <img src={require("../assets/images/demirorenmedya-414.png")} className='demirorenmedya-img' alt="demirorenmedya" />
        </picture>

        <div className="about_text-box">
          <h4 className='title'>{t("Institution_profile")}</h4>
          <p>{t("about_page_p1")}</p>

          <p>{t("about_page_p2")} <strong>{t("about_page_p3")}</strong></p>

          <p><span className='about_text-box_bold'>Mersis No </span> <span className='about_text-box_desc'>0289068528000001</span></p>
          <p><span className='about_text-box_bold'>{t("Trade_name")} </span> <span className='about_text-box_desc'>DEMİRÖREN HABERLEŞME HİZMETLERİ VE TELEKOMİNİKASYON TİCARET A. Ş.</span></p>
        </div>
        
        <BGYSList />
        <CertificatesList />
    </div>
  )
}
