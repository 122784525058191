import { useForm } from "react-hook-form";
import React, { useRef } from 'react';
import './styles/ContactForm.scss';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

export default function PostForm(props) {
  let apiPrefix = "";
  const { t } = useTranslation();
  const { register, handleSubmit, formState, reset, setValue, getValues, formState: { errors, isValid, isSubmitted, isSubmitSuccessful }} = useForm();
  const [submittedData, setSubmittedData] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [acceptkvkk, setAcceptkvkk] = React.useState(false);
  const [approveRecapt, setApproveRecapt] = React.useState(false);
  
  const recaptchaRef = useRef(null);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const accept = () => {
    setShow(false);
    setAcceptkvkk(true);
  };
  
  const checkedControl = () => {
    setAcceptkvkk(!acceptkvkk)
  };

  React.useEffect(() => {
    if (approveRecapt && formState.isSubmitSuccessful) {
      reset()
      setAcceptkvkk(false)
      successConrtol()
      recaptchaRef.current.reset()
      setApproveRecapt(false)
    }
  }, [formState, submittedData, reset])

  const successConrtol = () => {
      props.setSuccessinfo(true)
      setTimeout(() => {
        props.setSuccessinfo(false)
      }, 4000);
  }

  const onSubmit=(data)=> {

      if (!data.recaptchaToken) {
        console.log("reCAPTCHA token is missing.");
      } else {
      setApproveRecapt(true)
      const body = `Ad Soyad: ${data.AdSoyad},</br> Mail Adres: ${data.MailAddress} , </br> Mail İçeriği: ${data.Mailİçeriği}`
      const allData = {"body": body}

      if(document.URL.indexOf("pilot.") > 0){
        apiPrefix = "http://api-pilot.demirorenhaberlesme.com.tr/api/email/sendemail"
        } else {
        apiPrefix = "https://api.demirorenhaberlesme.com.tr/api/email/sendemail"
        }

      fetch(apiPrefix, {
        method: "POST",
        headers: {'Content-Type': 'application/json'}, 
        body: JSON.stringify(allData),
      }).then(res => {
        console.log("Request complete! response:", res);
      }).catch((error) => {
        console.error("Error fetching data:", error);
      });
  
      setSubmittedData(allData);
    }
  }
  
  return (
    <div>
      <script src="https://www.google.com/recaptcha/api.js" async defer></script>
       <Modal className="modal-md" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t("Kvkk_contract")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe src={require('../assets/pdf/PT.OR.12_0 Demiröen Haberlesme - Genel Aydınlatma Metni.pdf') +'#toolbar=0&navpanes=0&scrollbar=0'}
                  width="100%"
                  height="100%"
                  frameborder="0">
            </iframe>
            <Link className="pdf" to="/files/PT.OR.12_0 Demiröen Haberlesme - Genel Aydınlatma Metni.pdf" target='_blank' download>{t("pdf_download")}</Link>
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={accept}>
              {t("Kvkk_approval")}
            </Button>
          </Modal.Footer>
        </Modal>
      <form onSubmit={handleSubmit(onSubmit)} className="formgroup">
        <div className="formgroup-item">
          <label>{t("Name_surname")}*</label>
          <input placeholder={t("Write_your_name_surname")}
            {...register("AdSoyad", {required: true, maxLength: 80, pattern: /^[a-zA-Z-ığüşöçIİĞÜŞÖÇ ]+$/i})} 
            />
            {errors?.AdSoyad?.type === "required" && <p className="red-er">{t("required_area")}</p>}
            {errors?.AdSoyad?.type === "maxLength" && <p className="red-er">{t("You_have_exceeded_the_maximum_length")}</p>}
            {errors?.AdSoyad?.type === "pattern" && <p className="red-er">{t("Enter_only_alphabetic_characters")}</p>}
        </div>

        <div className="formgroup-item">
          <label>{t("Your_Email_Address")}*</label>
          <input placeholder={t("Enter_your_email_address")}
            {...register("MailAddress", {required: true, pattern: /^\S+@\S+$/i})} />
          {errors?.MailAddress?.type === "required" && <p className="red-er">{t("required_area")}</p>}
          {errors?.MailAddress?.type === "pattern" && <p className="red-er">{t("Please_Write_Mail_Format")}</p>}
        </div>

        <div className="formgroup-item">
          <label>{t("Your_message")}*</label>
          <textarea
            placeholder={t("Write_the_subject_you_want_to_convey_to_us")}
            {...register("Mailİçeriği", {required: true, maxLength: 300})} />
            {errors?.Mailİçeriği?.type === "required" && <p className="red-er">{t("required_area")}</p>}
        </div>

        <div className="formgroup-item-checkbox">
          <label className="container">
            <input
              id="myCheckbox"
              type="checkbox"
              checked= {acceptkvkk}
              onClick={checkedControl}
              {...register('isKVKK', {required: !acceptkvkk})}
            />
            <span className={isSubmitted && !isSubmitSuccessful && !isValid && !acceptkvkk ? "checkmark red" : "checkmark "}></span>
            <label htmlFor="isKVKK" className="form-check-label">

              {!isSubmitted ? ( <a onClick={handleShow}><p dangerouslySetInnerHTML={{ __html: t("I_have_read_and_accept_the_KVKK_Agreement") }}></p></a>) : ''}
              {isSubmitted && !isSubmitSuccessful && !isValid && !acceptkvkk ? ( <a onClick={handleShow} className="red-er"><p dangerouslySetInnerHTML={{ __html: t("I_have_read_and_accept_the_KVKK_Agreement") }}></p></a>) : ''}
              {isValid && isSubmitted ? ( <a onClick={handleShow}><p dangerouslySetInnerHTML={{ __html: t("I_have_read_and_accept_the_KVKK_Agreement") }}></p></a>) : ''}
              {isSubmitted && !isSubmitSuccessful && !isValid && acceptkvkk ? ( <a onClick={handleShow}><p dangerouslySetInnerHTML={{ __html: t("I_have_read_and_accept_the_KVKK_Agreement") }}></p></a>) : ''}
              
            </label>
          </label>
        </div>
        <div>{formState.isValid}</div>

        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LenvsInAAAAAGdY2YIndW6juFsoaFtCP8Ot8OpE"
          onChange={(token) => setValue('recaptchaToken', token)}
        />

        {!approveRecapt && <p className="pt-2 red-er">{t("recaptcha")}</p>}

        <button className={isSubmitted  && !isValid || isSubmitted && isValid && !acceptkvkk ? 'gray-btn-clr' : ''} type="submit"><p>{t("Send")}</p></button>
      </form>
    </div>
  );
}