import React from 'react'
import './styles/InfoButton.scss';

export default function InfoButton({title, customClass, showIcon, iconUrl}) {
  return (
    <p className={`${customClass}` }>
      {showIcon ? <img src={iconUrl} alt={title} /> : null}
      {title}
    </p>
  )
}
