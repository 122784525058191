const BgysList = [
    {
        id:0,
        title: "bgys_title"
    },
    {
        id:1,
        title:"bgys_desc"
    },
    {
        id: 3,
        title:"bgys0"
    },
    {
        id: 4,
        title:"bgys1"
    },
    {
        id: 5,
        title:"bgys2"
    },
    {
        id: 6,
        title:"bgys3"
    },
    {
        id: 7,
        title:"bgys4"
    },
    {
        id: 8,
        title:"bgys5"
    },
    {
        id: 9,
        title:"bgys6"
    },
    {
        id: 10,
        title:"bgys7"
    },
    {
        id: 11,
        title:"bgys8"
    }
]
export default BgysList