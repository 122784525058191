import React from 'react'
import './styles/Home.scss';
import MoreButton from '../components/MoreButton';
import InfoButton from '../components/InfoButton';
import HomeServicesComponent from '../components/HomeServicesComponent';
import ContactUs from '../components/ContactUs';
import AboutBlackList from '../components/AboutBlackList';
import HomeReference from '../components/HomeReference';
import { useTranslation } from 'react-i18next';
import sample from '../assets/dunya.mp4';

export default function Home() {
  const {t} = useTranslation();
  
  return (
    <div>
      <section className='container-world'>
        <video className='videoTag' autoPlay loop muted>
          <source src={sample} type='video/mp4' />
        </video>
        <div>
          <p className='world_title'>{t("High_Quality_and_Uninterrupted_Access_Privilege_over_Satellite")}</p>
          <p className='world_desc'>{t("world_desc")}</p>
          <MoreButton title={t("More_Information")} customClass='showHover' icon={require('../assets/images/Forward.png')} color='#000' bgColor='#FCAF17' url='/kurumsal'/>
        </div>

      </section>
    
     
      <AboutBlackList customClassName='home-page'/>

      <section className='container-about'>
        <InfoButton title={t("Who_Are_We")} customClass="info-btn"/>
        <p className='desc'>{t("home_about_desc")}</p>
        <MoreButton title={t("More_Information")} customClass='showHover' icon={require('../assets/images/Forward.png')} color='#000' bgColor='#FCAF17' url='/kurumsal'/>
      </section>
      <picture>
          <source media="(max-width:415px)" srcSet={require("../assets/images/demirorenmedya-414.png")} />
          <source media="(max-width:768px)" srcSet={require("../assets/images/demirorenmedya-768.png")} />
          <source media="(min-width:769px)" srcSet={require('../assets/images/demirorenmedya.png')} />
          <img src={require("../assets/images/demirorenmedya-414.png")} className='demirorenmedya-img' alt="demirorenmedya" />
      </picture>
      <HomeReference />
      <HomeServicesComponent key={Math.random()}/>
      <ContactUs />
    </div>
  )
}