import React from 'react';
import './styles/Referance.scss';
import Logos from '../constructors/LogoList';
import ContactUs from '../components/ContactUs';
import { useTranslation } from 'react-i18next';

export default function Referance() {
  const {t} = useTranslation();
  return (
    <>
      <div className='referance-header'>
        <div className='referance-header__text'>
          {t("referance_title")}
        </div>
      </div>
      <div className='referance-logolist'>
      <div className='referance-bg-image'></div>
        {Logos.map((item) =>
          <img key={item.id} src={require(`../assets/images/channel-${item.id}.png`)} alt={item.name} />
        )}
      </div>
      <ContactUs/>
    </>
  )
}
