const LogoList = [
  {
      id: 0,
      name: "KanalD",
  },
  {
      id: 1,
      name: "EuroD",
  },
  {
      id: 2,
      name: "CNNTurk",
  },
  {
      id: 3,
      name: "teve2",
  },
  {
    id: 4,
    name: "Taytv",
  },
  {
    id: 5,
    name: "NHKworld",
  },
  {
    id: 6,
    name: "boomerang",
  },
  {
    id: 7,
    name: "CartoonNetwork",
  },
  {
    id: 8,
    name: "Eurosport",
  },
  {
    id: 9,
    name: "CNN",
  },
  {
    id: 10,
    name: "Discovery",
  },
  {
    id: 11,
    name: "DreamTV",
  },
  {
    id: 12,
    name: "DreamTurk",
  },
  {
    id: 13,
    name: "Fanatik",
  },
  {
    id: 14,
    name: "CNNturk",
  },
  {
    id: 15,
    name: "DailyNews",
  },
  {
    id: 16,
    name: "DHA",
  },
  {
    id: 17,
    name: "Dsmart",
  },
  {
    id: 18,
    name: "Hürriyet",
  },
  {
    id: 19,
    name: "Hürriyet.com.tr",
  },
  {
    id: 20,
    name: "Milliyet",
  },
  {
    id: 21,
    name: "Posta",
  },
  {
    id: 22,
    name: "BBCnews",
  },
  {
    id: 23,
    name: "RadyonD",
  },
  {
    id: 24,
    name: "FightScreen",
  },
  {
    id: 25,
    name: "Fashiontv",
  },
  {
    id: 26,
    name: "Milliyercomtr",
  },
  {
    id: 27,
    name: "Insıghttv",
  },
  {
    id: 28,
    name: "MilliyetEmlak",
  },
  {
    id: 29,
    name: "Sanat",
  },
  {
    id: 30,
    name: "Lovenature",
  },
  {
    id: 31,
    name: "NBAtv",
  },
  {
    id: 32,
    name: "SCI",
  },
  {
    id: 33,
    name: "Natureescape",
  },
  {
    id: 34,
    name: "Viasathistory",
  },
  {
    id: 35,
    name: "YaySat",
  },
  {
    id: 36,
    name: "Vatan",
  },
  {
    id: 37,
    name: "DocuScreen",
  },
  {
    id: 38,
    name: "GameScreen",
  },
];

export default LogoList;