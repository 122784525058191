// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.more-btn {
  background-color: #FCAF17;
  color: #000;
  padding: 13px 30px 13px 34px;
  border-radius: 40px;
  margin: 20px 0px;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  gap: 10px;
  transition: all 500ms;
  font-size: 16px;
  line-height: 22.4px;
}
@media screen and (max-width: 415px) {
  .more-btn {
    font-size: 14px;
  }
}
@media screen and (max-width: 350px) {
  .more-btn {
    padding: 13px 20px;
  }
}
@media screen and (max-width: 415px) {
  .more-btn_icon {
    width: 18px;
    height: 18px;
  }
}

.showHover:hover {
  gap: 20px;
}

.pdf-download {
  margin: 15px 0px 0px;
  width: 100%;
  justify-content: center;
}
@media screen and (max-width: 740px) {
  .pdf-download {
    padding: 13px 20px;
    font-size: 16px;
    gap: 5px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/styles/MoreButton.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,WAAA;EACA,4BAAA;EACA,mBAAA;EACA,gBAAA;EACA,qBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,SAAA;EACA,qBAAA;EACA,eAAA;EACA,mBAAA;AACJ;AACI;EAfJ;IAgBQ,eAAA;EAEN;AACF;AADI;EAlBJ;IAmBQ,kBAAA;EAIN;AACF;AADQ;EADJ;IAEQ,WAAA;IACA,YAAA;EAIV;AACF;;AADA;EACI,SAAA;AAIJ;;AAFA;EACI,oBAAA;EACA,WAAA;EACA,uBAAA;AAKJ;AAJI;EAJJ;IAKQ,kBAAA;IACA,eAAA;IACA,QAAA;EAON;AACF","sourcesContent":[".more-btn {\n    background-color: #FCAF17;\n    color: #000;\n    padding: 13px 30px 13px 34px;\n    border-radius: 40px;\n    margin: 20px 0px;\n    text-decoration: none;\n    display: flex;\n    align-items: center;\n    width: fit-content;\n    gap: 10px;\n    transition: all 500ms;\n    font-size: 16px;\n    line-height: 22.4px;\n    \n    @media screen and (max-width: 415px) {\n        font-size: 14px;\n    }\n    @media screen and (max-width: 350px) {\n        padding: 13px 20px;\n    }\n\n    &_icon {\n        @media screen and (max-width: 415px) {\n            width: 18px;\n            height: 18px;\n        }\n    }\n}\n.showHover:hover {\n    gap: 20px;\n}\n.pdf-download {\n    margin: 15px 0px 0px;\n    width: 100%;\n    justify-content: center;\n    @media screen and (max-width: 740px) {\n        padding: 13px 20px;\n        font-size: 16px;\n        gap: 5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
