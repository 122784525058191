const ServiceList = [
    {
        id: 0,
        title: 'Broadcast_Transmission_via_Satellite'
    },
    {
        id: 1,
        title: "Compression_Services"
    },
    {
        id: 2,
        title: "Content_Intake_and_Distribution"
    },
    {
        id: 3,
        title: "Monitoring_Reporting_Services"
    },
    {
        id: 4,
        title: "Playout_Services"
    },
    {
        id: 5,
        title: "OTT_Transcoding"
    },
    {
        id: 6,
        title: "Disaster _Recovery"
    }
];
export default  ServiceList;