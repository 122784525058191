import React from 'react'
import './styles/ContactUs.scss';
import MoreButton from './MoreButton';
import { useTranslation } from 'react-i18next';
export default function ContactUs() {
  const {t} = useTranslation();
  return (
    <div className='contact-us'>
        <div className='contact-us_text'>
            <h4 className='contact-us_text-title'>{t("Contact_us")}</h4>
            <p className='contact-us_text-desc'>{t("contact_us_desc")}</p>
            <MoreButton title={t("Become_Our_Partner")} bgColor="#000" textColor="#fff" url="/iletisim"  icon={require('../assets/images/forward-white.png')} />
        </div>
    </div>
  )
}
