const CertificateList = [
    {
        id:0,
        title:"Bilgi Güvenliği Politikası PT.OR.01",
        url:"PT.OR.01 Bilgi Güvenliği Politikası.pdf"
    },
    {
        id:1,
        title:"ISO 27001 EN",
        url:"ISO 27001 EN.pdf"
    },
    {
        id:2,
        title:"ISO 27001 TR",
        url:"ISO 27001 TR.pdf"
    },
    
];
export default CertificateList;