import React, { useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./styles/Navbar.scss";
import { useTranslation } from "react-i18next";
import NavDropdown from "react-bootstrap/NavDropdown";

export default function Navbar() {
  const { t, i18n } = useTranslation();
  const [openedMenu, setOpenedMenu] = useState(false);
  const navbarCollapse = useRef();
  const navigate = useNavigate();

  const handleClick = (val) => {
    setOpenedMenu(val);
    navbarCollapse.current.classList.toggle("show");
  };

  const changeLanguage = (value) => {
    i18n.changeLanguage(value);
  };

  const brandHandleClick = () => {
    if (navbarCollapse.current.classList.contains("show")) {
      setOpenedMenu(false);
      navbarCollapse.current.classList.remove("show");
    }
  };

  const dropdownHandleCLick = (val) => {
    if (val === "genel") {
      navigate("kisisel-verilerin-korunmasi", {
        state: {
          title: "General_Clarification_Text",
          desc: "Personal_data_desc_general",
          url: "/files/PT.OR.12_0 Demiröen Haberlesme - Genel Aydınlatma Metni.pdf",
        },
      });
    }
    if (val === "kişi") {
      navigate("kisisel-verilerin-korunmasi", {
        state: {
          title: "Contact_Application_Form",
          desc: "Personal_data_desc_personal",
          url: "/files/PT.OR.14_0 Demirören Haberleşme-İlgili Kisi Basvuru Formu.pdf",
        },
      });
    }
  };

  return (
    <nav
      className={
        openedMenu ? "navbar_custom navbar_opened-menu" : "navbar_custom"
      }
    >
      <Link
        to="/"
        className="navbar_brand"
        onClick={() => brandHandleClick()}
      />

      <div className="show-md">
        <img
          src={require("../assets/images/list.png")}
          className={
            openedMenu ? "navbar_list-icon" : "navbar_list-icon  show-md"
          }
          alt="list"
          onClick={() => handleClick(true)}
        />
        <img
          src={require("../assets/images/close.png")}
          onClick={() => handleClick(false)}
          className={
            openedMenu ? "navbar_close-icon show-md" : "navbar_close-icon  "
          }
          alt="close"
        />
      </div>
      <div className="navbar_collapse" ref={navbarCollapse}>
        <div className="navbar_links">
          <NavLink
            to="/kurumsal"
            className="links"
            onClick={() => handleClick(false)}
          >
            {t("Corporate")}
          </NavLink>
          <NavLink
            to="/hizmetlerimiz"
            className="links"
            onClick={() => handleClick(false)}
          >
            {t("Our_Services")}
          </NavLink>
          <NavLink
            to="/referanslarimiz"
            className="links"
            onClick={() => handleClick(false)}
          >
            {t("References")}
          </NavLink>
          <NavLink
            to="/iletisim"
            className="links"
            onClick={() => handleClick(false)}
          >
            {t("Contact")}
          </NavLink>

          <NavDropdown
            title={t("Protection_of_Personal_Data")}
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item onClick={() => dropdownHandleCLick("genel")}>
              {t("General_Clarification_Text")}
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => dropdownHandleCLick("kişi")}>
              {t("Contact_Application_Form")}
            </NavDropdown.Item>
          </NavDropdown>
        </div>
        <div className="navbar_lang">
          <span
            onClick={() => changeLanguage("TR")}
            className={i18n.language === "TR" ? "active" : ""}
          >
            TR
          </span>
          <span
            onClick={() => changeLanguage("EN")}
            className={i18n.language === "EN" ? "active" : ""}
          >
            EN
          </span>
        </div>
      </div>
    </nav>
  );
}
