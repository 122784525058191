// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personal-data {
  padding: 64px 60px 20% 60px;
}
.personal-data h1 {
  font-family: Articulat CF;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 24px;
}
.personal-data h4 {
  color: #000;
  font-family: Articulat CF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 54px;
}
.personal-data p {
  font-family: Articulat CF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
.personal-data a {
  color: #FCAF17;
  font-family: Articulat CF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/styles/PersonalData.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;AACJ;AACI;EACI,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AACR;AAEI;EACI,WAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAAR;AAGI;EACI,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AADR;AAII;EACI,cAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;AAFR","sourcesContent":[".personal-data {\n    padding: 64px 60px 20% 60px;\n\n    h1 {\n        font-family: Articulat CF;\n        font-size: 36px;\n        font-style: normal;\n        font-weight: 700;\n        line-height: 120%;\n        margin-bottom: 24px;\n    }\n\n    h4 {\n        color: #000;\n        font-family: Articulat CF;\n        font-size: 20px;\n        font-style: normal;\n        font-weight: 600;\n        line-height: 40px;\n        margin-bottom: 54px;\n    }\n\n    p {\n        font-family: Articulat CF;\n        font-size: 18px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 27px;\n    }\n\n    a {\n        color: #FCAF17;\n        font-family: Articulat CF;\n        font-size: 18px;\n        font-style: normal;\n        font-weight: 700;\n        line-height: 27px;\n        text-decoration: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
