import React from 'react'
import ServicesArray from '../constructors/ServicesArray';
import { useTranslation } from "react-i18next";

const ServicesItem = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {ServicesArray.map((item) =>
          <div key={item.id} className= {item.imgPosition === 'right' ? "services-right__img" : ""  }>
            <div className='services-item'>
              <div className='services-item__img'>
                <picture>
                  {item.imgDesktop && <source media="(min-width:1440px)" srcSet={require(`../assets/images/${item.imgDesktop}.png`)} />}
                  <img className={item.img} src={require(`../assets/images/${item.img}.png`)} alt="demirorenmedya" />
                </picture>
              </div>
              <div className='services-item__text'>
                <div className='badgetitle'>{t(item.ticket)}</div>
                <div className='services-title'>{t(item.service)}</div>
                <div className='services-text'>{t(item.content)}</div>
                {item.contentItem && 
                  <div className='services-content'>
                    {item.contentItem?.map((item, index) =>
                      <div key={index} className='services-content-item'>
                        <picture>
                          <img src={require(`../assets/images/Select.png`)} alt="select" />
                        </picture>
                        <div>
                        {t(item.title)}
                        </div>
                      </div>
                    )}
                  </div>
                }
              </div>
            </div>
            {item.img2 && 
              <div className='services-second-area'>
                <picture>
                  {item.img2Desktop && <source media="(min-width:1440px)" srcSet={require(`../assets/images/${item.img2Desktop}.png`)} />}
                  <source media="(min-width:768px)" srcSet={require(`../assets/images/${item.img2Tablet}.png`)} />
                  <img src={require(`../assets/images/${item.img2}.png`)} alt="demirorenmedya" />
                </picture>
              </div>
            }
          </div>
      )}
    </React.Fragment>
  )
}

export {ServicesItem};