export default {
    translation: {
        "Corporate":"Corporate",
        "Who_Are_We":"Who Are We",
        "Our_Services": "Our Services",
        "References": "Our References",
        "Contact": "Contact",
        "Contact_Application_Form": "Contact Application Form",
        "Get_Support": "Get Support",
        "Follow_us":"Follow Us",
        "Processing_of_Personal_Data_Clarification_Text":"Processing of Personal Data Clarification Text",
        "General_Clarification_Text": "General Clarification Text",
        "10th_Year_in_the_Industry":"10th Year in the Industry",
        "Professional_Services":"Professional Services",
        "80_Solution_Partners":"80 Solution Partners",
        "We_Touch_the_Lives_of_Millions_of_People_with_Our_Values_and_Goals":"We Touch the Lives of Millions of People with Our Values and Goals.",
        "See_All":"See All",
        "Satellite_Broadcast_Transmission_Services_Coverage_Area":"Satellite Broadcast Transmission Services Coverage Area",
        "yayin-text_desc": "Demirören Communication Services provides radio and television broadcast transmission services over the Türksat 4A satellite, using its technological infrastructure within the scope of Turkey and Turkey + Europe. A redundant infrastructure is also provided for Radio and TV channels wishing to receive satellite broadcast transmission service to come to our Teleport via internet or point-to-point circuits.",
        "Compression_Services":"Compression Services",
        "compression_desc":"We provide encoding and multiplexing services in MPEG2, H264 formats in DVB standards with our IP-based compression systems, each of which is fully redundant in itself.",
        "All_Our_Services":"All Our Services",
        "Broadcasting": "Broadcasting",
        "Broadcast_Transmission_via_Satellite":"Broadcast Transmission via Satellite",
        "Content_Intake_and_Distribution":"Content Intake and Distribution",
        "Monitoring_Reporting_Services": "Monitoring & Reporting Services",
        "Playout_Services": "Playout Services", 
        "OTT_Transcoding":"OTT Transcoding",   
        "Disaster _Recovery":"Disaster Recovery",
        "High_Quality_and_Uninterrupted_Access_Privilege_over_Satellite":"High Quality and Uninterrupted Access Privilege over Satellite",
        "world_desc":"Demirören Communication Services provides satellite broadcasting services to TV and Radio channels, using the satellite communication infrastructure and satellite capacity leased from Türksat A.Ş.",
        "More_Information":"More Information",
        "home_about_desc": "Demirören Communication Services; It operates within the largest media organization in Turkey, which includes leading in-group channels such as Kanal D, CNNTürk, teve2, Dream Türk, and Turkey's leading digital broadcasting platform D-Smart, providing platform services to its corporate customers through a total of two data centers located in Istanbul Headquarters and Ankara Disaster Recovery Center.",
        "about_title":"We Touch the Lives of Millions of People in Different Sectors with the Principle of Sustainable Growth.",
        "about_page_p1":"Demirören Communication Services provides satellite broadcasting services to TV and Radio channels, using the satellite communication infrastructure and satellite capacities leased from Türksat A.Ş.",
        "about_page_p2":"Demirören Communication Services operates within Turkey's largest media organization, which includes leading in-group channels such as Kanal D, CNN Türk, teve2, Dream Türk and Turkey's leading digital broadcasting platform D-Smart, ",
        "about_page_p3":"providing platform services to its corporate customers through a total of two data centers located in Istanbul Headquarters and Ankara Disaster Center.",
        "Trade_name": "Trade Name",
        "Contact_us":"Contact Us",
        "contact_us_desc": "As Demirören Communication Services, we invite you to be a business partner, so you will have the opportunity to benefit from our quality satellite broadcasting services without interruption.",
        "Become_Our_Partner": "Become Our Partner",
        "contact_page_desc":"Contact us for your questions, comments and suggestions.",
        "Name_surname": "Name Surname",
        "Write_your_name_surname": "Write your name and surname",
        "Your_Email_Address":"Your Email Adress",
        "Enter_your_email_address":"Enter your email address",
        "Your_message": "Your Message",
        "Write_the_subject_you_want_to_convey_to_us": "Write the subject you want to convey to us...",
        "Send": "Send",
        "I_have_read_and_accept_the_KVKK_Agreement":"I have read and accept the <b>KVKK Agreement.</b>",     
        "You_have_exceeded_the_maximum_length":"You have exceeded the maximum length",
        "Enter_only_alphabetic_characters": "Enter only alphabetic characters",
        "Services_header_title_text":"Demirören Satellite Services Offers Solutions in Many Business Areas.",
        "Services_header_subtitle_text":"Demirören Communications Satellite Services, Media, Broadcasting, Satellite Networks,It offers products and services in the fields of teleport, TV distribution and Uplink & Downlink.",
        "Services_broadcast_title":"Broadcast Transmission via Satellite",
        "Services_broadcast_text_1":"Demirören TV provides encrypted and unencrypted transmission services to 81 TV and 62 radio services on 4 TPs. Demirören TV is Turkey's largest private satellite operator authorized by RTÜK and BTK.",
        "Services_broadcast_text_2":"Among the channels served, there are global channels such as NHK, Cartoon Network, Da Vinci, Cartoonito, and many channels with a local focus.",
        "Compression_services":"Compression Services",
        "Compression_services_content":"We provide encoding and multiplexing services in MPEG2, H264 formats in DVB standards with our IP-based compression systems, each of which is fully redundant in itself.",
        "Content_intake_and_distribution":"Content Intake and Distribution",
        "Content_intake_and_distribution_content":"In our infrastructure, there are redundant endpoints of p2p circuits of telecommunication companies such as Türk Telekom, Superonline and Vodafone.Türk Telekom and Superonline redundant network infrastructures are also used in our systems, such as Zixi, SRT, RTMP, for the reception and transmission of broadcasts over the public internet.",
        "Rf_systems":"RF Systems",
        "Rf_systems_content":"7x24 broadcast continuity is ensured with our redundant uplink - downlink systems in our Ankara and Istanbul locations.",
        "Monitoring_reporting_services":"Monitoring & Reporting_services",
        "Loudness_monitoring":"Loudness Monitoring",
        "Loudness_monitoring_content":"According to EBU R128 standards, the sound level should be -23 LUFS. We are trying to reach the target level in our broadcasts with the DVB Loudness software.",
        "Uptime_raporting":"Uptime raporting",
        "Uptime_raporting_content":"The broadcasts of our customers we serve through Uptime Report Manager are monitored and reported 7x24.",
        "Playout_services":"Playout Services",
        "Playout_services_content":"Demirören TV provides playout and monitoring services to 22 channels such as Cartoon Network and Boomerang, as well as intra-group channels such as Kanal D and CNN Türk.",
        "All_ip_based":"All Ip Based",
        "Complex_chart_tools":"Complex Chart Tools",
        "Control_monitoring":"24/7 Control And Monitoring",
        "Live_streaming_services":"Live Streaming Services",
        "State_of_the_art_automation":"State Of The Art Automation",
        "Full_redundancy":"Full Redundancy",
        "Kvkk_contract":"KVKK CONTRACT",
        "form_sent":"Your contact form has been successfully submitted.",
        "Kvkk_approval":"I have read, I accept.",
        "OTT":"OTT Transcoding Services",
        "OTT_content":"Transcoding services are provided for live or VOD content using HLS, DASH, SMOOTH STREAMING and FP, WV and PR encryption methods.",
        "Infrastructure":"Infrastructure",
        "Disaster_recovery_services":"Disaster Recovery Services",
        "Disaster_recovery_services_content":"The transport stream outputs of the multiplexers are delivered to the Ankara DR center via Metroethernet, and in case of bad weather conditions, the uplink system at the Ankara Disaster Recovery Center is activated. The transfer of broadcasts to the Ankara Disaster Recovery Center is carried out smoothly through competent programs and professional procedures.",
        "referance_title":"The importance we place on the quality of our work and establishing a productive business partnership with our customers makes us grow. We would be happy to see you among us.",
        "required_area":"This area is required.",
        "Please_Write_Mail_Format":"Please enter in e-mail format",
        "Protection_of_Personal_Data":"Protection of Personal Data",
        "Institution_profile": "Institution Profile",
        "Our_ISMS_Policy":"Our ISMS Policy",
        "bgys0":"5.2.1. Identification of all processes and information assets and their risk assessments in accordance with the standard.",
        "bgys1":"5.2.2. Protecting the confidentiality, integrity and availability of DHH information",
        "bgys2":"5.2.3. Minimizing the risks arising from unauthorized access, loss, corruption or misuse of DHH information assets.",
        "bgys3":"5.2.4. The risks identified by the Risk Committee and monitored periodically by the Information Security Department are explained in the Risk Assessment Table. Risk Appetite Level is taken to the lowest possible level and kept under control, especially those above the Risk Appetite Level.",
        "bgys4":"5.2.5. Legal obligations and fulfillment of legal obligations arising from contracts",
        "bgys5":"5.2.6. Establishment of necessary procedures, policies and instructions to support this policy",
        "bgys6":"5.2.7. Managing the existing ISMS structure by carrying out the necessary studies within the scope of continuous improvement",
        "bgys7":"5.2.8. Providing periodic Information Security trainings to all employees within the scope of continuous improvement",
        "bgys8":"5.2.9. Ensuring that all information security breaches or suspected breaches are reported and investigated.",
        "pdf_download":"Download PDF",
        "Our_certificates":"Our Certificates",
        "bgys_title":"5.1. This policy has been approved by the General Manager of DHH.",
        "bgys_desc":"5.2. Information Security Policy secures all the following requirements:",
        "recaptcha":"Please complete the reCAPTCHA",
        "here": "here.",
        "Personal_data_desc_general": "You can access the general information text ",
        "Personal_data_desc_personal": "You can access the contact application form ",
    }
}