import React from 'react'
import './styles/Footer.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Footer() {
  const {t} = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className='footer_dark-container'>
        <div className='footer_brand'></div>
        <div className='footer_content'>
          <div className='footer_links'>
            <Link to="/kurumsal">{t("Corporate")} </Link>
            <Link to="/hizmetlerimiz">{t("Our_Services")}</Link>
            <Link to="/referanslarimiz">{t("References")}</Link>
            <Link to="/iletisim">{t("Contact")}</Link>
            <Link to="/files/PT.OR.12_0 Demiröen Haberlesme - Genel Aydınlatma Metni.pdf" target='_blank' download>{t("General_Clarification_Text")}</Link>
            <Link to="/files/PT.OR.14_0 Demirören Haberleşme-İlgili Kisi Basvuru Formu.pdf" target='_blank' download>{t("Contact_Application_Form")}</Link>
          </div>
          <div className='footer_text'>
          <Link to="/files/PT.OR.12_0 Demiröen Haberlesme - Genel Aydınlatma Metni.pdf" target='_blank' download className='text-white show-md download-text'>{t("General_Clarification_Text")}</Link>
          <Link to="/files/PT.OR.14_0 Demirören Haberleşme-İlgili Kisi Basvuru Formu.pdf" target='_blank' download className='text-white show-md download-text'>{t("Contact_Application_Form")}</Link>
            <span className='text-gray flex-md'>{t("Get_Support")} <span className='text-white footer_tel'>0 800 219 2021</span></span>
            <Link to="https://www.linkedin.com/company/demirören-medya/" target="_blank" className='text-gray flex-md'>{t("Follow_us")}  <img src={require('../assets/images/linkedin.png')} alt="linkedin" className='linkedin'/></Link>
          </div>
        </div>
      </div>
      <div className='footer_white-container'>
        <span className='fw-400'>© {currentYear} demirorenhaberlesme.com - All Rights Reserved</span>
      </div>
    </footer>
  )
}
