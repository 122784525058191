import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContactUs from "../components/ContactUs";
import "./styles/PersonalData.scss";

export default function PersonalData() {
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const { title, desc, url } = state;

  return (
    <div>
      <div className="personal-data">
        <h1>{t("Protection_of_Personal_Data")}</h1>
        <h4>{t(title)}</h4>
        <p>
          {t(desc)}
          <a href={url} download target="_blank" rel="noreferrer">
            {t("here")}
          </a>
          {i18n.language === "TR" ? " ulaşabilirsiniz." : ""}
        </p>
      </div>
      <ContactUs />
    </div>
  );
}
