import React from "react";
import BgysList from '../constructors/BGYSList';
import { useTranslation } from 'react-i18next';
import "./styles/BGYSList.scss";

export default function BGYSList() {
    const {t} = useTranslation();
    return (
        <div className="bgys-list">
            <h4 className="title">{t("Our_ISMS_Policy")}</h4>
            {BgysList.map((item) => (
                <p key={item.id}>{t(item.title)}</p>
            ))}
            <span>15.08.2023 - PT.OR.01_4</span>
        </div>
    );
}
