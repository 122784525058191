// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bgys-list {
  padding: 2% 27% 1%;
}
@media screen and (max-width: 768px) {
  .bgys-list {
    padding: 3% 5% 1%;
  }
}
@media screen and (max-width: 768px) {
  .bgys-list {
    padding: 7% 7% 3%;
  }
}
.bgys-list p {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}
.bgys-list p:nth-child(n+4) {
  margin-left: 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/BGYSList.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACI;EAHJ;IAIQ,iBAAA;EAEN;AACF;AAAI;EAPJ;IAQQ,iBAAA;EAGN;AACF;AADI;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;AAGR;AAFQ;EACI,iBAAA;AAIZ","sourcesContent":[".bgys-list {\n    padding: 2% 27% 1%;\n\n    @media screen and (max-width:768px) {\n        padding: 3% 5% 1%; \n    }\n\n    @media screen and (max-width:768px) {\n        padding: 7% 7% 3%; \n    }\n    \n    p {\n        font-size: 18px;\n        font-weight: 400;\n        line-height: 27px;\n        letter-spacing: 0em;\n        text-align: left;\n        &:nth-child(n + 4)  {\n            margin-left: 50px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
