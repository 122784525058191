export default {
    translation: {
        "Corporate":"Kurumsal",
        "Who_Are_We":"Biz Kimiz",
        "Our_Services": "Hizmetlerimiz",
        "References": "Referanslarımız",
        "Contact": "İletişim",
        "Contact_Application_Form":"İlgili Kişi Başvuru Formu",
        "Get_Support": "Destek Alın",
        "Follow_us":"Bizi Takip Edin",
        "Processing_of_Personal_Data_Clarification_Text":"Kişisel Verilerin İşlenmesi Aydınlatma Metni",
        "General_Clarification_Text": "Genel Aydınlatma Metni",
        "10th_Year_in_the_Industry":"Sektörde 10. Yıl",
        "Professional_Services":"Profesyonel Hizmetler",
        "80_Solution_Partners":"80 Çözüm Ortağı",
        "We_Touch_the_Lives_of_Millions_of_People_with_Our_Values_and_Goals":"Değerlerimiz ve Hedeflerimizle Milyonlarca İnsanın Hayatlarına Dokunuyoruz.",
        "See_All":"Tümünü Gör",
        "Satellite_Broadcast_Transmission_Services_Coverage_Area":"Uydu Yayın İletim Hizmetleri Kapsama Alanı",
        "yayin-text_desc":"Demirören Haberleşme Hizmetleri, Türkiye ve Türkiye + Avrupa kapsamında teknolojik altyapısını kullanarak Türksat 4A uydusu üzerinden radyo ve televizyon yayın iletim hizmeti sunmaktadır. Uydu yayın iletim hizmeti almak isteyen Radyo ve TV kanallarının Teleport’umuza internet veya noktadan noktaya devrelerle gelmesi için yedekli alt yapı da sunulmaktadır.",
        "Compression_Services":"Compression Hizmetleri",
        "compression_desc":"IP tabanlı her biri kendi içinde tamamen yedekli compression  sistemlerimiz ile DVB standartlarında MPEG2, H264 formatlarında encoding ve multiplexing hizmetleri vermekteyiz.",
        "All_Our_Services":"Tüm Hizmetlerimiz",
        "Broadcasting": "Yayın",
        "Broadcast_Transmission_via_Satellite":"Uydu Üzerinden Yayın İletimi",
        "Content_Intake_and_Distribution":"İçerik Alım ve Dağıtım",
        "Monitoring_Reporting_Services":"Monitoring & Raporlama Hizmetleri",
        "Playout_Services": "Playout Hizmetleri",
        "OTT_Transcoding":"OTT Transcoding",
        "Disaster _Recovery":"Felaket Kurtarma",
        "High_Quality_and_Uninterrupted_Access_Privilege_over_Satellite":"Uydu Üzerinden Yüksek Kalite ve Kesintisiz Erişim Ayrıcalığı",
        "world_desc":"Demirören Haberleşme Hizmetleri, Uydu haberleşme altyapısını ve Türksat A.Ş.’den kiraladığı uydu kapasitesini kullanarak TV ve Radyo kanallarına yapılan sözleşme kapsamında uydu yayın hizmeti vermektedir.",
        "More_Information":"Daha Fazla Bilgi",
        "home_about_desc": "Demirören Haberleşme Hizmetleri; Kanal D, CNNTürk, teve2, Dream Türk gibi alanında lider grup içi kanalları, ve Türkiye’nin önde gelen dijital yayın platformu D-Smart’ı bünyesinde bulunduran, kurumsal müşterilerine İstanbul Merkez ve Ankara Felaket Kurtarma Merkezi’nde yer alan toplam iki adet data center üzerinden  platform hizmeti veren, Türkiye’nin en büyük medya organizasyonu içinde faaliyet göstermektedir.",
        "about_title":"Sürdürülebilir Büyüme Prensibi ile Farklı Sektörlerde Milyonlarca İnsanın Hayatına Dokunuyoruz.",
        "about_page_p1":"Demirören Haberleşme Hizmetleri, uydu haberleşme altyapısını ve Türksat A.Ş.’den kiraladığı uydu kapasitelerini kullanarak TV ve Radyo kanallarına yapılan sözleşme kapsamında uydu yayın hizmeti vermektedir.",
        "about_page_p2":"Demirören Haberleşme Hizmetleri Kanal D, CNN Türk, teve2, Dream Türk gibi alanında lider grup içi kanalları ve Türkiye’nin önde gelen dijital yayın platformu D-Smart’ı bünyesinde bulunduran, kurumsal müşterilerine ",
        "about_page_p3":"İstanbul Merkez ve Ankara Felaket Merkezi’nde yer alan toplam iki adet data center üzerinden  platform hizmeti veren Türkiye’nin en büyük medya organizasyonu içinde faaliyet göstermektedir.",
        "Trade_name": "Ticaret Ünvanı",
        "Contact_us":"Bizimle İletişime Geçin",
        "contact_us_desc":"Demirören Haberleşme Hizmetleri olarak sizi iş ortağı olmaya çağırıyoruz böylece kaliteli uydu yayın hizmetlerimizden kesintisiz olarak yararlanma fırsatı bulacaksınız.",
        "Become_Our_Partner":"İş Ortağımız Olun",
        "Infrastructure":"Altyapı",
        "contact_page_desc":"Merak ettikleriniz, görüş ve önerileriniz için bize ulaşın.",
        "Name_surname": "Adınız Soyadınız",
        "Write_your_name_surname": "Adınız Soyadınızı yazınız",
        "Your_Email_Address":"Email Adresiniz",
        "Enter_your_email_address":"Mail adresini giriniz",
        "Your_message": "Mesajınız",
        "Write_the_subject_you_want_to_convey_to_us": "Bize iletmek istediğiniz konuyu yazınız...",
        "Send": "Gönder",
        "I_have_read_and_accept_the_KVKK_Agreement":"<b>KVKK Sözleşmesi</b>’ni okudum ve kabul ediyorum.",
        "This_field_is_required":"Bu alan Zorunludur",       
        "You_have_exceeded_the_maximum_length":"Maksimum uzunluğu geçtiniz",
        "Enter_only_alphabetic_characters":"Sadece alfabetik karakter giriniz",
        "Services_header_title_text":"Demirören Uydu Hizmetleri Bir Çok İş Alanında Çözüm Sunmaktadır.",
        "Services_header_subtitle_text":"Demirören Haberleşme Uydu Hizmetleri, Medya, Yayıncılık, Uydu Networkleri, Teleport, TV dağıtımı ve Uplink & Downlink faaliyet alanlarında ürün ve hizmet sunar.",
        "Kvkk_contract":"KVKK SÖZLEŞMESİ",
        "Kvkk_approval":"Okudum, Kabul Ediyorum",
        "form_sent":"İletişim formunuz başarıyla gönderildi.",
        "Services_broadcast_title":"Uydu Üzerinden Yayın İletimi",
        "Services_broadcast_text_1":"Demirören TV 4 adet TP’de 81 TV, 62 radyo servisine şifreli ve şifresiz olarak iletim hizmeti vermektedir. Demirören TV Türkiye’nin RTÜK ve BTK yetkili en büyük özel uydu operatörüdür.",
        "Services_broadcast_text_2":"Hizmet verilen kanallar arasında NHK, Cartoon Network, Da Vinci, Cartoonito gibi global kanallar ve yerel odaklı yayın yapan birçok kanal vardır.",
        "Compression_services":"Compression Hizmetleri",
        "Compression_services_content":"IP tabanlı her biri kendi içinde tamamen yedekli compression sistemlerimiz ile DVB standartlarında MPEG2, H264 formatlarında encoding ve multiplexing hizmetleri vermekteyiz.",
        "Content_intake_and_distribution":"İçerik Alım ve Dağıtım",
        "Content_intake_and_distribution_content":"Altyapımızda Türk Telekom, Superonline ve Vodafone gibi telekomunikasyon firmalarının p2p devrelerinin yedekli sonlanma noktaları bulunmaktadır.Sistemlerimizde Zixi, SRT, RTMP gibi public internet üzerinden yayın alımı ve iletimi için Türk Telekom ve Superonline yedekli network altyapıları da kullanılmaktadır.",
        "Rf_systems":"RF sistemleri",
        "Rf_systems_content":"Ankara ve İstanbul lokasyonlarımızda bulunan yedekli uplink - downlink sistemlerimizle 7x24 yayın devamlılığı sağlanmaktadır.",
        "Monitoring_reporting_services":"Monitoring & Raporlama Hizmetleri",
        "Loudness_monitoring":"Loudness Monitoring",
        "Loudness_monitoring_content":"EBU R128 standartlarına göre ses seviyesi -23 LUFS olması gerekmektedir. DVB Loudness yazılımı ile yayınlarımızda hedef seviyeyi yakalamaya çalışıyoruz.",
        "Uptime_raporting":"Uptime Raporlama",
        "Uptime_raporting_content":"Uptime Report Manager aracılığıyla hizmet verdiğimiz müşterilerimizin yayınları 7x24 takip edilip raporlanmaktadır.",
        "Playout_services":"Playout Hizmetlerimiz",
        "Playout_services_content":"Demirören TV bünyesinde Kanal D,CNN Türk gibi grup içi kanalların yanı sıra Cartoon Network, Boomerang gibi 22 adet kanala playout ve monitoring hizmeti verilmektedir.",
        "All_ip_based":"Tamamı IP Tabanlı",
        "Complex_chart_tools":"Kompleks Grafik Araçları",
        "Control_monitoring":"24/7 Kontrol ve İzleme",
        "Live_streaming_services":"Canlı Yayın Hizmetleri",
        "State_of_the_art_automation":"Son Teknoloji Otomasyon",
        "Full_redundancy":"Tam Yedeklilik",
        "OTT":"OTT Transcoding Hizmetleri",
        "OTT_content":"Live ya da VOD içerikler HLS, DASH, SMOOTHSTREAMING kullanarak FP, WV ve PR şifreleme yöntemiyle transcoding hizmetleri verilmektedir.",
        "Disaster_recovery_services":"Felaket Kurtarma Hizmetleri",
        "Disaster_recovery_services_content":"Multiplexerların transport stream çıkışları Metroethernet aracılığıyla Ankara DR merkezine ulaştırılır ve kötü hava koşulları olduğu durumlarda Ankara Felaket Kurtarma Merkezi’ndeki uplink sistemi devreye alınmaktadır. Yayınların Ankara Felaket Kurtarma Merkezi’ne aktarılması yetkin programlar ve profesyonel prosedürler aracılığıyla sorunsuz bir şekilde gerçekleştirilmektedir.",
        "referance_title":"Yaptığımız çalışmaların kalitesine ve müşterilerimizle verimli bir iş ortaklığı kurmaya verdiğimiz önem bizi büyütüyor. Sizleri de aramızda görmekten mutluluk duyarız.",
        "required_area":"Bu alan zorunludur",
        "Please_Write_Mail_Format":"Lütfen mail formatında giriniz",
        "Protection_of_Personal_Data":"Kişisel Verilerin Korunması",
        "Institution_profile": "Kurum Profili",
        "Our_ISMS_Policy":"BGYS Politikamız",
        "bgys0":"5.2.1. Tüm süreçler ve bilgi varlıklarının tanımlanması ve bunların risk değerlendirmelerinin standarda uygun şekilde yapılması.",
        "bgys1":"5.2.2. DHH bilgilerinin gizliliği, bütünlüğü ve erişilebilirliğinin korunması",
        "bgys2":"5.2.3. DHH bilgi varlıklarının yetkisiz erişim, kaybolması, bozulması veya kötüye kullanılmasından ortaya çıkan risklerin en aza indirilmesi.",
        "bgys3":"5.2.4. Risk Komitesi tarafından tespit edilmiş ve Bilgi Güvenliği Departmanı tarafından periyodik olarak takip edilen risklerin, Risk Değerlendirme Tablosunda açıklandığı üzere Risk İştah Düzeyinin üzerindekiler öncelikli olmak üzere mümkün olan en düşük seviyeye çekilmesi ve kontrol altında tutulması",
        "bgys4":"5.2.5. Yasal yükümlülükler ve sözleşmelerden doğan hukuki yükümlülüklerin yerine getirilmesi",
        "bgys5":"5.2.6. Bu politikayı desteklemek için gerekli prosedür, politika ve talimatların oluşturulması",
        "bgys6":"5.2.7. Mevcut BGYS yapısının sürekli iyileştirme kapsamında gerekli çalışmaların yapılarak yönetilmesi",
        "bgys7":"5.2.8. Sürekli iyileştirme kapsamında tüm çalışanlara periyodik olarak Bilgi Güvenliği eğitimlerinin sağlanması",
        "bgys8":"5.2.9. Tüm bilgi güvenliği ihlallerin veya ihlal şüphesinin bildirilmesinin ve incelenmesinin sağlanması.",
        "pdf_download":"PDF İndir",
        "Our_certificates":"Sertifikalarımız",
        "bgys_title":"5.1. Bu politika DHH Genel Müdürü tarafından onaylanmıştır.",
        "bgys_desc":"5.2. Bilgi Güvenliği Politikası aşağıdaki tüm gereksinimleri güvence altına alır:",
        "recaptcha":"Lütfen reCAPTCHA'yı tamamlayın",
        "here": "buradan",
        "Personal_data_desc_general": "Genel aydınlatma metnine ",
        "Personal_data_desc_personal": "İlgili kişi başvuru formuna ",
    }
}