import React from "react";
import ContactUs from "../components/ContactUs";
import { ServicesItem } from "../components/ServicesItem";
import "./styles/Services.scss";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className="services">
      <div className="services-header">
        <div className="services-header-title__text">
          {t("Services_header_title_text")}
        </div>
        <div className="services-header-subtitle__text">
          {t("Services_header_subtitle_text")}
        </div>
      </div>

      <div className="services-broadcast">
        <div className="services-bg-image"></div>
        <div className="services-broadcast__text-frame">
          <div className="badgetitle">{t("Brodcasting")}</div>
          <div className="services-broadcast__title">
            {t("Services_broadcast_title")}
          </div>
          <div className="services-broadcast__text">
            {t("Services_broadcast_text_1")}
          </div>
          <div className="services-broadcast__text">
            {t("Services_broadcast_text_2")}
          </div>
          <div className="services-broadcast__list">
            <div className="services-broadcast__list-item">
              <div>
                <img src={require("../assets/images/HD.png")} alt="HD" />
              </div>

              <div className="text">
                <b>HD</b> (FTA: 7 / SCRAMBLED: 33)
              </div>
            </div>

            <div className="services-broadcast__list-item">
              <div>
                <img src={require("../assets/images/SD.png")} alt="SD" />
              </div>

              <div className="text">
                <b>SD</b> (FTA: 25)
              </div>
            </div>

            <div className="services-broadcast__list-item">
              <div>
                <img src={require("../assets/images/Radio.png")} alt="Radio" />
              </div>

              <div className="text">
                <b>RADIO</b> (FTA: 12 / SCRAMBLED: 50)
              </div>
            </div>
          </div>

          <div className="marklist">
            <img src={require("../assets/images/davinci.png")} alt="Radio" />
            <img src={require("../assets/images/channel-6.png")} alt="Radio" />
            <img src={require("../assets/images/channel-7.png")} alt="Radio" />
            <img src={require("../assets/images/channel-8.png")} alt="Radio" />
            <img src={require("../assets/images/channel-31.png")} alt="Radio" />
            <img src={require("../assets/images/channel-5.png")} alt="Radio" />
            <img src={require("../assets/images/channel-10.png")} alt="Radio" />
          </div>
        </div>
        <div className="services-broadcast__img">
          <picture>
            <source
              media="(min-width:1440px)"
              srcSet={require("../assets/images/anten-1440.png")}
            />
            <source
              media="(min-width:768px)"
              srcSet={require("../assets/images/antenna-768.png")}
            />
            <img src={require("../assets/images/antenna.png")} alt="Antenna" />
          </picture>
        </div>
      </div>
      <ServicesItem />
      <ContactUs />
    </div>
  );
};

export { Services };
